import i18next from 'i18next'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Typography from '../components/common/Typography'

import Layout from '../components/Layout/Layout'
import SEO from '../components/Layout/SEO/SEO'

export default function DataProtection() {
  // hook needed to rerender upon language change
  const { t } = useTranslation()
  return (
    <>
      <SEO
        title="Pop Rocket | Datenschutz"
        description="Wir liefern Interaktionen, die begeistern. Gamification, motivierendes Design und maßgeschneiderte Entwicklung sorgen für unvergessliche digitale Erlebnisse."
        tabTitle="Datenschutz"
      />
      <Layout>
        <StyledDataPrivacySection>
          <StyledDataPrivacyHeading type="h1">
            <Trans i18nKey={i18next.t('terms.title')} />
          </StyledDataPrivacyHeading>

          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.date" />
          </StyledDataPrivacyParagraph>
          <StyledDataPrivacyParagraphHeading>
            <Trans i18nKey="terms.overview.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledList>
            <StyledListItem>
              <a href="#m3">
                <Trans i18nKey="terms.overview.entry_one" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#mOverview">
                <Trans i18nKey="terms.overview.entry_two" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#m13">
                <Trans i18nKey="terms.overview.entry_three" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#m27">
                <Trans i18nKey="terms.overview.entry_four" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#cookie-section">
                <Trans i18nKey="terms.overview.entry_five" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#m317">
                <Trans i18nKey="terms.overview.entry_six" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#m3575">
                <Trans i18nKey="terms.overview.entry_seven" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#m225">
                <Trans i18nKey="terms.overview.entry_eight" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#m408">
                <Trans i18nKey="terms.overview.entry_nine" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#m263">
                <Trans i18nKey="terms.overview.entry_ten" />
              </a>
            </StyledListItem>
            <StyledListItem>
              <a href="#m42">
                <Trans i18nKey="terms.overview.entry_eleven" />
              </a>
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraphHeading id="m3">
            <Trans i18nKey="terms.controller.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.controller.copy" />
          </StyledDataPrivacyParagraph>

          <StyledDataPrivacyParagraphHeading>
            <Trans i18nKey={i18next.t('terms.processing.title')} />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.processing.copy" />
          </StyledDataPrivacyParagraph>
          <StyledDataPrivacyListHeading>
            <Trans i18nKey="terms.processing.category_processed_data.title" />
          </StyledDataPrivacyListHeading>
          <StyledList>
            <StyledListItem>
              <Trans
                i18nKey={i18next.t(
                  'terms.processing.category_processed_data.category_one'
                )}
              />
            </StyledListItem>
            <StyledListItem>
              <Trans
                i18nKey={i18next.t(
                  'terms.processing.category_processed_data.category_two'
                )}
              />
            </StyledListItem>
            <StyledListItem>
              <Trans
                i18nKey={i18next.t(
                  'terms.processing.category_processed_data.category_three'
                )}
              />
            </StyledListItem>
            <StyledListItem>
              <Trans
                i18nKey={i18next.t(
                  'terms.processing.category_processed_data.category_four'
                )}
              />
            </StyledListItem>
            <StyledListItem>
              <Trans
                i18nKey={i18next.t(
                  'terms.processing.category_processed_data.category_five'
                )}
              />
            </StyledListItem>
            <StyledListItem>
              <Trans
                i18nKey={i18next.t(
                  'terms.processing.category_processed_data.category_six'
                )}
              />
            </StyledListItem>
            <StyledListItem>
              <Trans
                i18nKey={i18next.t(
                  'terms.processing.category_processed_data.category_seven'
                )}
              />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyListHeading>
            <Trans i18nKey="terms.processing.category_data_subject.title" />
          </StyledDataPrivacyListHeading>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.processing.category_data_subject.category_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.category_data_subject.category_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.category_data_subject.category_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.category_data_subject.category_four" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.category_data_subject.category_five" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.category_data_subject.category_six" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.category_data_subject.category_seven" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyListHeading>
            <Trans i18nKey="terms.processing.process_purpose.title" />
          </StyledDataPrivacyListHeading>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_four" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_five" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_six" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_seven" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_eight" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_nine" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.processing.process_purpose.purpose_ten" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraphHeading id="m13">
            <Trans i18nKey="terms.legal_base.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.legal_base.copy_one" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.legal_base.references.reference_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.legal_base.references.reference_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.legal_base.references.reference_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.legal_base.references.reference_four" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.legal_base.copy_two" />
          </StyledDataPrivacyParagraph>
          <StyledDataPrivacyParagraphHeading id="m27">
            <Trans i18nKey="terms.security.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.security.copy" />
          </StyledDataPrivacyParagraph>
          <StyledDataPrivacyParagraphHeading id="cookie-section">
            <Trans i18nKey="terms.cookies.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.cookies.copy_one" />
          </StyledDataPrivacyParagraph>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.cookies.duration.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.cookies.duration.entry_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.cookies.duration.entry_two" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.cookies.copy_two" />
          </StyledDataPrivacyParagraph>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.cookies.services_used.title" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.cookies.services_used.entry" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraphHeading id="m317">
            <Trans i18nKey="terms.services.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.services.copy_one" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.services.entries.entry_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.services.entries.entry_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.services.entries.entry_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.services.entries.entry_four" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.services.copy_two" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.services.entries_two.entry_one" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraphHeading id="m3575">
            <Trans i18nKey="terms.providers.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.providers.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.providers.entries.entry_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.providers.entries.entry_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.providers.entries.entry_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.providers.entries.entry_four" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.providers.further_information.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.providers.further_information.information" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraphHeading id="m225">
            <Trans i18nKey="terms.provision.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.provision.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.provision.entries.entry_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.provision.entries.entry_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.provision.entries.entry_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.provision.entries.entry_four" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.provision.further_information.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.provision.further_information.entry_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.provision.further_information.entry_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.provision.further_information.entry_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.provision.further_information.entry_four" />
            </StyledListItem>
          </StyledList>

          <StyledDataPrivacyParagraphHeading id="m408">
            <Trans i18nKey="terms.survey.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.survey.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.survey.entries.entry_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.survey.entries.entry_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.survey.entries.entry_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.survey.entries.entry_four" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraphHeading id="m263">
            <Trans i18nKey="terms.analysis.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.analysis.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.analysis.entries.entry_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.analysis.entries.entry_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.analysis.entries.entry_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.analysis.entries.entry_four" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.analysis.entries.entry_five" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.analysis.further_information.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.analysis.further_information.entry_one" />
            </StyledListItem>
          </StyledList>
          <StyledDataPrivacyParagraphHeading id="m42">
            <Trans i18nKey="terms.terminology.title" />
          </StyledDataPrivacyParagraphHeading>
          <StyledDataPrivacyParagraph>
            <Trans i18nKey="terms.terminology.copy" />
          </StyledDataPrivacyParagraph>
          <StyledList>
            <StyledListItem>
              <Trans i18nKey="terms.terminology.entries.entry_one" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.terminology.entries.entry_two" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.terminology.entries.entry_three" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.terminology.entries.entry_four" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.terminology.entries.entry_five" />
            </StyledListItem>
            <StyledListItem>
              <Trans i18nKey="terms.terminology.entries.entry_six" />
            </StyledListItem>
          </StyledList>
        </StyledDataPrivacySection>
      </Layout>
    </>
  )
}

const StyledDataPrivacySection = styled.section`
  width: 100%;
  text-align: left;
  max-width: 118rem;
  padding: 2.4rem;
  color: ${({ theme }) => theme.palette.global.darkGrey};

  a {
    cursor: pointer;
    width: 100%;
    margin: 2rem 0 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.company.prlBlue};
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    ${({ theme }) => theme.breakpoints.queries.sm} {
      :hover {
        svg {
          animation-name: Arrow-Right-Animation;
          animation-duration: 0.6s;
          animation-delay: 0s;
          animation-fill-mode: forwards;
        }
      }
    }
  }
`
const StyledDataPrivacyHeading = styled(Typography)`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.palette.global.accent};
  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 3.3rem;
  }
`
const StyledDataPrivacyParagraph = styled.p`
  padding: 1rem 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
`

const StyledDataPrivacyParagraphHeading = styled.h2`
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 500;
  padding: 1rem 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
`
const StyledDataPrivacyListHeading = styled.h3`
  line-height: 3rem;
  font-weight: 500;
  font-size: 1.6rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 2rem;
    line-height: 3.6rem;
  }
`

const StyledList = styled.ul`
  margin: 0 0 2rem 0;
`

const StyledListItem = styled.li`
  padding: 0.4rem;
  list-style: disc inside;
  font-size: 1.6rem;
  line-height: 2.2rem;
`
